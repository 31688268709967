<template>
  <div
    v-if="product"
    class="product"
  >
    <div
      v-if="productImages.length > 0"
      class="product__photo-wrap"
    >
      <ProductImage
        v-for="(image, index) in productImages"
        :key="`image-${index}`"
        :url="image"
        :width="80"
        :height="80"
      />
    </div>
    <div
      v-if="productImages.length === 0"
      class="product__photo-wrap"
    >
      <ProductImage
        :width="80"
        :height="80"
      />
    </div>
    <template v-if="uniqueAttributes.length > 0">
      <div class="product__sub-header">
        {{ $t('models.uniqueAttributes') }}
      </div>
      <div class="product__attributes">
        <Attribute
          v-for="attribute in uniqueAttributes"
          :key="attribute.key"
          :attrKey="attribute.key"
          :value="attribute.value"
        />
      </div>
    </template>
    <Form
     as="div"
     v-if="isSelfVariation || (!isSelfVariation && isVariationUsed)"
     v-slot="{ meta }"
     :style="{display: 'block'}"
    >
      <div class="product__separator">
      </div>
      <div class="product__offer">
        <input-with-label-validation
          class="product__offer-item_first"
          vid="price"
          :rules="{ required: true, moreThan: { min: 0 }}"
          :name="$t('modelPrice')"
          v-model="newVariation.price"
          :required="true"
          :label="$t('modelPrice')"
          :tooltip="{ title: $t('models.priceTipTitle'), text: $t('models.priceTipText') }"
          type="number"
          sign="$"
          :disabled="isSelfVariation"
        />
        <input-with-label-validation
          class="product__offer-item"
          :rules="{ lessThan: { max: '@price'}, minValue: { min: 0 } }"
          :name="$t('modelDiscountPrice')"
          v-model="newVariation.discountPrice"
          :required="false"
          :label="$t('modelDiscountPrice')"
          :tooltip="{ title: $t('models.discountPriceTipTitle'), text: $t('models.discountPriceTipText') }"
          type="number"
          sign="$"
          :disabled="isSelfVariation"
        />
        <input-with-label-validation
          class="product__offer-item"
          rules="required|integer|positive"
          :name="$t('modelQuantity')"
          v-model="newVariation.stock"
          :required="true"
          :label="$t('modelQuantity')"
          :placeholder="$t('modelQuantity')"
          type="number"
          :disabled="isSelfVariation"
        />
        <input-with-label-validation
          class="product__offer-item_last"
          :rules="{ required: true, exclude: { list: skuList }}"
          :name="$t('modelSKU')"
          v-model="newVariation.sku"
          :required="true"
          :label="$t('modelSKU')"
          :tooltip="{ title: $t('models.skuTipTitle'), text: $t('models.skuTipText') }"
          :placeholder="$t('modelSKU')"
          :disabled="isSelfVariation"
        />
      </div>
      <slot name="product-buttons" v-bind:invalid="!meta.valid" v-bind:dirty="meta.dirty"></slot>
    </Form>
    <div class="product__separator">
    </div>
    <div
      v-if="!isSelfVariation"
      class="product__submit"
    >
      <BaseButton
        v-if="!isVariationUsed"
        @click="isVariationUsed = true"
      >
        {{$t('models.useThisVariation')}}
      </BaseButton>
      <BaseButton
        v-if="isVariationUsed"
        :disabled="isSaving"
        @click="addVariation()"
      >
        {{$t('models.makeOffer')}}
      </BaseButton>
    </div>
    <div
      v-if="isSelfVariation"
      class="product__submit_accepted"
    >
      {{$t('models.offerAccepted')}}
      <svg-icon class="accepted-icon" icon-name="checkmark-filled"></svg-icon>
    </div>

  </div>
</template>

<script>
import api from '@/api/api'
import { mapState } from 'vuex'
import { Form } from 'vee-validate'
import ProductImage from '@/components/ui/images/ProductImage'
import BaseButton from '@/components/ui/buttons/BaseButton'
import Attribute from '@/components/ui/layout/models/Attribute'
import SvgIcon from '@shared/components/SvgIcon'
import helpers from '@/helpers/helpers'
import InputWithLabelValidation from '../forms/InputWithLabelValidation'

export default {
  name: 'ProductCard',
  components: {
    InputWithLabelValidation,
    ProductImage,
    BaseButton,
    Attribute,
    Form,
    SvgIcon,
  },
  props: {
    product: {
      type: Object,
    },
    skuList: {
      type: Array,
      default: () => [],
    },
    isSelfVariation: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isVariationUsed: false,
      newVariation: {
        stock: null,
        price: null,
        discountPrice: null,
        status: 'active',
        sku: '',
      },
      uniqueAttributeList: null,
      isSaving: false,
    }
  },

  computed: {
    ...mapState({
      products: state => state.products.myModel.products,
      productsAvailable: state => state.products.productsAvailable,
      attributes: state => state.model.attributes,
    }),

    uniqueAttributes () {
      const attributes = []
      if (!this.product?.attributes || this.product?.attributes?.length === 0) return attributes
      const productAttributes = helpers.productAttributes(this.products, this.productsAvailable)
      const keys = productAttributes.uniqueKeys
      for (const attribute of this.product.attributes) {
        if (!attribute?.key?.name) continue
        if (keys.includes(attribute.key?.name)) {
          attributes.push({
            key: attribute.key.name,
            value: attribute.value.name,
          })
        }
      }
      return attributes
    },

    productImages () {
      if (!this.product) return []
      return this.product.images && this.product.images.length > 0 ? this.product.images : []
    },
  },

  methods: {
    clearVariation () {
      this.newVariation = {
        stock: null,
        price: null,
        discountPrice: null,
        status: 'active',
        sku: '',
      }
      this.isVariationUsed = false
    },

    async addVariation () {
      try {
        this.isSaving = true
        await api.makeOffer(this.product.id, this.newVariation)
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: this.$t('variationSuccessfulyAdded'),
          description: null,
        })
        this.clearVariation()
        await this.$store.dispatch('getModelById', this.$route.params.modelId)
        this.$emit('onCreate')
      } catch (error) {
        const errorText = (error.response && error.response.data && error.response.data.error &&
          error.response.data.error.message) ? error.response.data.error.message : error
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: this.$t('variationCreateError'),
          description: errorText,
        })
      } finally {
        this.isSaving = false
      }
    },
  },

  mounted () {
    if (this.isSelfVariation) {
      this.newVariation.stock = this.product.stock || null
      this.newVariation.price = this.product.price || null
      this.newVariation.discountPrice = this.product.discountPrice || null
      this.newVariation.status = this.product.status || 'active'
      this.newVariation.sku = this.product.sku || ''
    }
  },
}
</script>

<style lang="scss" scoped>

.product {
  display: block;
  background-color: $color-white;
  margin-bottom: 8px;
  padding: 20px;

  &__photo-wrap {
    display: flex;
    flex-wrap: wrap;

    .product-image {
      margin: 0 16px 16px 0;
    }
  }

  &__sub-header {
    margin: 0 0 16px 0;
    color: $text-black;
    font: {
      size: 16px;
      weight: 400;
    }
  }

  &__attributes {
    width: 713px;
    margin: 0 0 16px 0;

    .attribute {
      margin: 0 0 4px 0;
      font-size: 14px;
    }
  }

  &__separator {
    display: block;
    margin: 0 0 16px 0;
    border-bottom: solid 1px $color-line;
  }

  &__offer {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 4fr 4fr 4fr;
  }

  &__offer-item {
    display: block;
    padding: 0 10px;

    &_first {
      padding: 0 10px 0 0;
    }

    &_last {
      padding: 0 0 0 10px;
    }
  }

  &__submit {
    text-align: right;

    &_accepted {
      text-align: right;
      padding: 20px 0 12px 0;
      color: $color-primary;
      font: {
        size: 16px;
        weight: 700;
      }

      .accepted-icon {
        vertical-align: middle;
        margin: -3px 0 0 4px;
      }
    }
  }
}
</style>
