<template>
  <div class="product">
    <div class="title text-20-800">
      {{ title }}
    </div>
    <Form as="div" v-slot="{ meta, validate, errors }">
      <div
        v-if="editIsDisabled === false"
        class="product-params __model-name"
      >
        <input-with-label-validation
          rules="required"
          :name="$t('models.variationName')"
          :style="{display: 'block'}"
          v-model="form.name"
          :required="true"
          :label="$t('models.variationName')"
          :tooltip="{ title: $t('models.modelNameTipTitle'), text: $t('models.modelNameTipText') }"
          :placeholder="$t('models.variationName')"
          :disabled="editIsDisabled"
        />
        <input-with-label-validation
          rules="required"
          :style="{display: 'block'}"
          :name="$t('hsCode')"
          v-model="form.hsCode"
          :required="true"
          :label="$t('hsCode')"
          :tooltip="{ title: $t('models.hsCodeTipTitle'), text: $t('models.hsCodeTipText') }"
          :placeholder="$t('hsCode')"
          :disabled="editIsDisabled"
        />
      </div>
      <div class="product-params">
        <input-with-label-validation
          :rules="{ required: true, exclude: { list: skuList }}"
          :style="{display: 'block'}"
          :name="$t('modelSKU')"
          v-model="form.sku"
          :required="true"
          :label="$t('modelSKU')"
          :tooltip="{ title: $t('models.skuTipTitle'), text: $t('models.skuTipText') }"
          :placeholder="$t('modelSKU')"
          :disabled="editIsDisabled"
        />
        <input-with-label-validation
          rules="required|integer|positive"
          :style="{display: 'block'}"
          :name="$t('modelQuantity')"
          v-model="form.stock"
          :required="true"
          :label="$t('modelQuantity')"
          :placeholder="$t('modelQuantity')"
          :disabled="editIsDisabled"
          type="number"
        />
        <input-with-label-validation
          vid="price"
          :rules="{ required: true, moreThan: { min: 0 }}"
          :style="{display: 'block'}"
          :name="$t('modelPrice')"
          v-model="form.price"
          :required="true"
          :label="$t('modelPrice')"
          :tooltip="{ title: $t('models.priceTipTitle'), text: $t('models.priceTipText') }"
          :disabled="editIsDisabled"
          type="number"
          sign="$"
        />
        <input-with-label-validation
          :style="{display: 'block'}"
          :name="$t('modelDiscountPrice')"
          v-model="form.discountPrice"
          :required="false"
          :label="$t('modelDiscountPrice')"
          :tooltip="{ title: $t('models.discountPriceTipTitle'), text: $t('models.discountPriceTipText') }"
          :disabled="editIsDisabled"
          type="number"
          sign="$"
        />
      </div>
      <div
        v-if="editIsDisabled === false"
        class="product-params"
      >
        <input-with-label-validation
          :rules="{ required: true, moreThan: { min: 0 }}"
          :style="{display: 'block'}"
          :name="$t('models.weight')"
          v-model="form.weight"
          :required="true"
          :label="$t('models.weight')"
          :tooltip="{ title: $t('models.weightTipTitle'), text: $t('models.weightTipText') }"
          :placeholder="$t('models.weight')"
          :disabled="editIsDisabled"
          type="number"
        />
        <input-with-label-validation
          :rules="{ required: true, moreThan: { min: 0 }}"
          :style="{display: 'block'}"
          :name="$t('models.length')"
          v-model="form.length"
          :required="true"
          :label="$t('models.length')"
          :tooltip="{ title: $t('models.lengthTipTitle'), text: $t('models.lengthTipText') }"
          :placeholder="$t('models.length')"
          :disabled="editIsDisabled"
          type="number"
        />
        <input-with-label-validation
          :rules="{ required: true, moreThan: { min: 0 }}"
          :style="{display: 'block'}"
          :name="$t('models.height')"
          v-model="form.height"
          :required="true"
          :label="$t('models.height')"
          :tooltip="{ title: $t('models.heightTipTitle'), text: $t('models.heightTipText') }"
          :placeholder="$t('models.height')"
          :disabled="editIsDisabled"
          type="number"
        />
        <input-with-label-validation
          :rules="{ required: true, moreThan: { min: 0 }}"
          :style="{display: 'block'}"
          :name="$t('models.width')"
          v-model="form.width"
          :required="true"
          :label="$t('models.width')"
          :tooltip="{ title: $t('models.widthTipTitle'), text: $t('models.widthTipText') }"
          :placeholder="$t('models.width')"
          :disabled="editIsDisabled"
          type="number"
        />
      </div>

      <template v-if="editIsDisabled === false">
        <!-- this ValidationProvider need for validate one or more attribute was selected -->
        <input-with-label-validation
          :rules="{moreThan: { min: 0}}"
          :name="$t('models.addAttr')"
          type="text"
          :value="form.attributes.length"
          disabled
          v-show="false"
        />
        <BaseExpansionPanel
          :contentFitHeader="true"
        >
          <template #header>
            <h2
              :class="{'__error': errors[$t('models.addAttr')] && errors[$t('models.addAttr')].length > 0}"
              class="text-20-800 h2 __no-border __no-margin"
            >
              <span class="required">*</span> {{ $t('models.addAttr') }}
              <TooltipIcon
                :title="$t('models.attributesTipTitle')"
                :text="$t('models.attributesTipText')"
              />
            </h2>
            <ul>
              <li
                class="error-item"
                v-for="(error, index) in Array.isArray(errors[$t('models.addAttr')]) ? errors[$t('models.addAttr')] : [errors[$t('models.addAttr')]]"
                :key="index">
                {{error}}
              </li>
            </ul>
          </template>
          <template #content>
            <AttributeList
              :attributes="localAttributes"
              :selectedAttributes="form.attributes"
              :disabled="editIsDisabled"
              @select="onSelectAttribute"
              @removeAttribute="onRemoveAttribute"
            />
          </template>

        </BaseExpansionPanel>

        <!-- this ValidationProvider need for validate form.images was added -->
        <input-with-label-validation
          :rules="{moreThan: { min: 0}}"
          :name="$t('models.addPhotos')"
          type="text"
          :value="form.images.length"
          disabled
          v-show="false"
        />
        <BaseExpansionPanel
          :contentFitHeader="true"
          :calcOnUpdate="true"
        >
          <template #header>
            <h2
              :class="{'__error': errors[$t('models.addPhotos')] && errors[$t('models.addPhotos')].length > 0}"
              class="text-20-800 h2 __no-margin __no-border"
            >
              <span class="required">*</span> {{ $t('models.addPhotos') }}
              <TooltipIcon
                :title="$t('models.photosTipTitle')"
                :text="$t('models.photosTipText')"
              />
            </h2>
            <ul>
              <li
                class="error-item"
                v-for="(error, index) in Array.isArray(errors[$t('models.addPhotos')]) ? errors[$t('models.addPhotos')] : [errors[$t('models.addPhotos')]]"
                :key="index">
                {{error}}
              </li>
            </ul>
          </template>
          <template #content>
            <div>
              <div class="images-description">
                {{ $t('models.photoRequirements') }}
              </div>
              <TheImageUploadForm
                :images="form.images"
                @setImages="images => form.images = images"
              />
              <!--
                this div need for call updated hook
                and calculate new height for open panel
              -->
              <div :style="{display: 'none'}">
                {{form.images}}
              </div>
            </div>
          </template>
        </BaseExpansionPanel>
      </template>

      <div class="product-images" v-if="editIsDisabled === true">
        <ProductImage
          v-for="(image, index) in form.images"
          :key="index"
          :url="image"
        />
      </div>

      <slot
        name="product-buttons"
        v-bind:invalid="!meta.valid"
        v-bind:dirty="meta.dirty"
        v-bind:validate="validate"
      >

      </slot>
    </Form>
  </div>
</template>

<script>
import BaseExpansionPanel from '@/components/ui/expansion/BaseExpansionPanel'
import TooltipIcon from '@/components/ui/forms/TooltipIcon'
import TheImageUploadForm from '@/components/ui/forms/TheImageUploadForm'
import AttributeList from '@/components/ui/lists/AttributeList'
import { Form } from 'vee-validate'
import ProductImage from '../images/ProductImage'
import InputWithLabelValidation from '../forms/InputWithLabelValidation'

const stringify = (object) => JSON.stringify(
  object,
  // eslint-disable-next-line
  (key, value) => (value === undefined ? null : value),
);

const isObjectEquals = (a, b) => stringify(a) === stringify(b);

const cloneObject = (object) => JSON.parse(stringify(object));

export default {
  name: 'TheProduct',
  components: {
    InputWithLabelValidation,
    ProductImage,
    AttributeList,
    TheImageUploadForm,
    TooltipIcon,
    BaseExpansionPanel,
    Form,
  },
  props: {
    value: {
      type: Object,
    },
    attributes: {
      type: Array,
    },
    title: {
      type: String,
      default: '',
    },
    editIsDisabled: {
      type: Boolean,
      default: false,
    },
    skuList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: undefined,
    };
  },
  computed: {
    localAttributes() {
      return cloneObject(this.attributes);
    },
  },
  watch: {
    form: {
      deep: true,
      handler(form) {
        if (form && !isObjectEquals(form, this.value)) {
          this.$emit('input', form);
        }
      }
    }
  },
  created() {
    this.form = cloneObject(this.value);
  },
  methods: {
    onSelectAttribute(attribute) {
      this.form.attributes.push(attribute);
    },
    onRemoveAttribute(keyId) {
      const index = this.form.attributes.findIndex(attribute => attribute.key.id === keyId)
      this.form.attributes.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.product {
  margin-bottom: 8px;
  padding: 30px 60px;
}
.product-params {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 3fr 4fr 4fr;
  margin-bottom: 7px;

  > *:not(:last-child) {
    margin-right: 30px;
  }

  &.__model-name {
    grid-template-columns: 12fr 4fr;
  }
}
.images-description {
  color: $text-gray;
  font-size: 14px;
  margin-bottom: 12px;
}
.product-images {
  display: flex;
  flex-wrap: wrap;

  > .product-image {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
.error-item {
  color: $color-error;
  padding: 4px 0;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}
.required {
  color: $color-error;
  margin-right: 5px;
}
.title {
  margin-bottom: 15px;
}
</style>
