<template>
  <div class="preview" @click="$emit('setMainImage', src)">
    <img
      :src="src"
      alt="product"
      class="img"
    />
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TheImagePreview',
  props: {
    src: String,
  },
}
</script>

<style lang="scss" scoped>
.preview {
  max-width: 100px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid $color-disabled;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transition: 0.2s all ease;
  }

  &:hover {
    border-color: $color-primary;
  }

  &:hover:before {
    background-color: rgba(52, 190, 187, 0.2);
  }
}
.img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: bottom;
}
</style>
