<template>
  <div class="new-variation">
    <div
      v-if="!isCollapsed"
      class="new-variation__collapse"
    >
      <div class="new-variation__collapse_title">
        <div class="text-20-800">{{title}}</div>
      </div>
      <div>
        <simple-button
          icon="plus-stroke"
          :iconSize="16"
          :value="$t('models.createVariation')"
          transparent
          :style="{ stroke: 'none'}"
          @click="isCollapsed = true"
        />
      </div>
    </div>
    <div v-if="isCollapsed && !isRestartValidationObservers">
      <TheProduct
        v-model="newProduct"
        :attributes="attributes"
        :title="$t('models.product')"
        :skuList="skuList"
        @removeAttribute="removeAttribute"
        @selectAttribute="selectAttribute"
      >
        <template v-slot:product-buttons="{ invalid, validate, errors }">
          <div class="button-wrap product-buttons">
            <simple-button
              icon="plus"
              :value="$t('models.addProductBtn')"
              :disabled="invalid"
              transparent
              @click="addProductToModel"
            />
            <simple-button
              :value="$t('models.saveFinish')"
              @click="() => { finish(invalid, validate)}"
            />
          </div>
        </template>
      </TheProduct>
    </div>

    <ModalOnOverlay
      v-if="overlay"
      @close="$store.commit('hideOverlay')"
    >
      <template #header>
        {{ $t('models.finishWarningTitle') }}
      </template>
      <template #body>
        {{ $t('models.finishWarningDescription') }}
      </template>
      <template #buttons>
        <WarnButton
          :value="$t('models.finishWithout')"
          @click="finishWithoutSaving"
        />
        <simple-button
          :value="$t('models.stayCorrect')"
          @click="$store.commit('hideOverlay')"
        />
      </template>
    </ModalOnOverlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api/api'
import TheProduct from '@/components/ui/layout/TheProduct'
import ModalOnOverlay from '@/components/ui/modals/ModalOnOverlay'
import WarnButton from '@/components/ui/buttons/WarnButton'

export default {
  name: 'NewVariationCard',

  components: {
    WarnButton,
    ModalOnOverlay,
    TheProduct,
  },

  props: {
    title: {
      type: String,
    },
    skuList: {
      type: Array,
      default: () => [],
    },
    initCollapsed: {
      type: Boolean,
    },
  },

  data () {
    return {
      newProduct: {
        sku: '',
        name: '',
        hsCode: '',
        weight: null,
        length: null,
        height: null,
        width: null,
        stock: '',
        price: '',
        discountPrice: '',
        status: 'active',
        attributes: [],
        images: [],
      },
      isCollapsed: false,
      isRestartValidationObservers: false,
    }
  },

  computed: {
    ...mapState({
      categoryId: state => state.products.myModel.category.id,
      attributes: state => state.model.attributes,
      dimensions: state => state.products.dimensions,
      name: state => state.products.myModel.name,
      hsCode: state => state.products.hsCode,
      overlay: state => state.ui.overlay,
    }),
  },

  methods: {
    clearNewProduct () {
      this.newProduct = {
        sku: '',
        name: this.name,
        hsCode: this.hsCode,
        weight: this.dimensions.weight,
        length: this.dimensions.length,
        height: this.dimensions.height,
        width: this.dimensions.width,
        stock: '',
        price: '',
        discountPrice: '',
        status: 'active',
        attributes: [],
        images: [],
      }
    },

    async addProduct () {
      try {
        const newProduct = JSON.parse(JSON.stringify(this.newProduct))
        newProduct.attributes = this.newProduct.attributes.map(attr => {
          return {
            key: attr.key.id,
            value: attr.value.id,
          }
        })
        await api.addProduct(this.$route.params.modelId, newProduct)
        this.$store.commit('showHintNotification', {
          type: 'SUCCESS',
          title: this.$t('variationSuccessfulyAdded'),
          description: null,
        })
        this.clearNewProduct()
      } catch (error) {
        await this.$store.dispatch('showError', error)
      }
    },

    async addProductToModel () {
      try {
        await this.addProduct()
      } catch (error) {
        throw error
      } finally {
        await this.$store.dispatch('getModelById', this.$route.params.modelId)
        this.restartValidationObservers()
      }
    },

    async finish (invalid, validate) {
      if (invalid === false) {
        await this.addProductAndGoToModel()
      } else {
        validate()
        this.$store.commit('showOverlay')
      }
    },

    finishWithoutSaving () {
      this.$store.commit('hideOverlay')
      this.$emit('onCreateFinish')
    },

    restartValidationObservers () {
      this.isRestartValidationObservers = true
      this.$nextTick(() => {
        this.isRestartValidationObservers = false
      })
    },

    async addProductAndGoToModel () {
      await this.addProduct()
      this.$emit('onCreateFinish')
      this.isCollapsed = false
    },

    removeAttribute (keyId) {
      const filteredAttributes = this.newProduct.attributes.filter(attribute => attribute.key.id !== keyId)
      this.newProduct.attributes = filteredAttributes
    },

    selectAttribute (attribute) {
      this.removeAttribute(attribute.key.id)
      this.newProduct.attributes.push(attribute)
    },
  },

  watch: {
    initCollapsed: {
      handler (initCollapsed) {
        this.isCollapsed = initCollapsed
      },
      immediate: true,
    },
    name: {
      handler () {
        this.clearNewProduct()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.new-variation {

  &__collapse {
    display: flex;
    height: 102px;
    line-height: 102px;
    margin: 0 0 8px 0;
    padding: 0 20px;
    align-items: center;


    &_title {
      flex-grow: 1;
    }
  }
}

.product-buttons {
  padding-top: 30px;
  border-top: 1px solid $color-line;
}
</style>
