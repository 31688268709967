<template>
  <div class="products-container">
    <NewVariationCard
      class="card"
      :title="$t('models.selectVariation')"
      :skuList="skuList"
      :initCollapsed="noProducts"
      @onCreate="fetchProductList"
      @onCreateFinish="toProductPage"
    />
    <template v-if="products && products.length > 0">
      <ProductCard
        class="card"
        v-for="product in products"
        :key="product.sku"
        :product="product"
        :skuList="skuList"
        :isSelfVariation="true"
      />
    </template>
    <template v-if="productsAvailable && productsAvailable.length > 0">
      <ProductCard
        class="card"
        v-for="product in productsAvailable"
        :key="product.sku"
        :product="product"
        :skuList="skuList"
        @onCreate="fetchProductList"
      />
    </template>
    <NewVariationCard
      class="card"
      v-if="!noProducts"
      :title="$t('models.notFoundVariation')"
      :skuList="skuList"
      :initCollapsed="false"
      @onCreate="fetchProductList"
      @onCreateFinish="toProductPage"
    />
    <ModalOnOverlay
      v-if="showLeaveModal"
      @close="closeModal"
    >
      <template #header>
        {{ $t('models.confirmTitle') }}
      </template>
      <template #body>
        {{ $t('models.confirmText') }}
      </template>
      <template #buttons>
        <WarnButton
          :value="$t('models.leave')"
          @click="leave"
        />
        <simple-button
          :value="$t('models.stay')"
          :transparent="true"
          @click="closeModal"
        />
      </template>
    </ModalOnOverlay>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductCard from '@/components/ui/layout/ProductCard'
import NewVariationCard from '@/components/ui/layout/NewVariationCard'
import ModalOnOverlay from '@/components/ui/modals/ModalOnOverlay'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import WarnButton from '@/components/ui/buttons/WarnButton'

export default {
  name: 'AddProductToModel',
  components: {
    ProductCard,
    NewVariationCard,
    ModalOnOverlay,
    SimpleButton,
    WarnButton,
  },

  data () {
    return {
      showLeaveModal: false,
      leaveRoute: '',
      ignoreModal: false,
      hasProducts: false,
    }
  },

  computed: {
    ...mapState({
      products: state => state.products.myModel.products,
      productsAvailable: state => state.products.productsAvailable,
    }),
    skuList () {
      return this.products.map(product => product.sku)
    },
    noProducts () {
      return this.productsAvailable.length === 0 && this.products.length === 0
    },
  },

  methods: {
    closeModal () {
      this.$store.commit('hideOverlay')
      this.showLeaveModal = false
    },

    leave () {
      this.$router.push(this.leaveRoute)
    },

    async fetchProductList () {
      try {
        const model = await this.$store.dispatch('getModelById', this.$route.params.modelId)
        if (!model?.category?.id) {
          this.ignoreModal = true
          await this.$router.push('/goods/1')
          return
        }
        await this.$store.dispatch('getAttrKey', { categoryId: model.category.id })
        await this.$store.dispatch('getProductsAvailable', this.$route.params.modelId)
      } catch (error) {
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: this.$t('models.fetchVariationsError'),
          description: error,
        })
      }
    },

    async toProductPage () {
      this.ignoreModal = true
      this.$router.push(`/model/${this.$route.params.modelId}`)
      await this.$store.dispatch('setDimension', [
        {
          name: 'weight',
          value: null,
        },
        {
          name: 'length',
          value: null,
        },
        {
          name: 'height',
          value: null,
        },
        {
          name: 'width',
          value: null,
        },
      ])
    },
  },

  beforeRouteLeave (to, from, next) {
    if (localStorage.getItem('api_token') === null) {
      next()
      return
    }

    if (this.showLeaveModal === false && this.ignoreModal === false) {
      this.$store.commit('showOverlay')
      this.leaveRoute = to.path
      this.showLeaveModal = true

      if (to.name === 'models.creating') {
        this.leaveRoute = '/goods/1'
      }
    } else {
      this.$store.commit('hideOverlay')
      this.showLeaveModal = false
      next()
    }
  },

  async mounted () {
    this.fetchProductList()
  },

  watch: {
    products: {
      handler (products) {
        this.hasProducts = !!(products && products.length > 0)
      },
      immediate: true,
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.products-container {
  flex-grow: 1;
  margin-left: 20px;
}

.card {
 border-radius: 20px;
 border: 1px solid #D4DADF;
 box-shadow: 0 0 24px 0 #0000000F;
  margin-bottom: 20px;
}
.title {
  margin-bottom: 20px;
}
</style>
