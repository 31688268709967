<template>
  <div class="wrapper">
    <div
      class="upload"
      :class="hoverUpload ? 'active' : ''"
      @dragleave="fileDragOut"
      @dragover="fileDragIn"
      @drop="handleFileDrop"
    >
      <svg-icon class="upload-icon" icon-name="upload"></svg-icon>
      <div class="upload__placeholder">
        {{$t('dragPhotos')}}
        <p>{{$t('or')}}</p>
        <span class="upload__click">{{$t('selectFiles')}}</span>
      </div>
      <input
        type="file"
        name="image"
        class="upload__input"
        multiple
        ref="fileupload"
        @drop.prevent
        @change="handleFileInput"
      >
    </div>
    <Form
      as="div"
      class="form-field upload__field"
      v-slot="{ meta }"
    >
      <input-with-label-validation
        rules="isUrl"
        :name="$t('imageUrl')"
        v-model="imageUrl"
        :label="$t('imageUrl')"
        :placeholder="$t('pasteImages')"
        :style="{ display: 'block', width: '440px', marginRight: '20px'}"
      />

      <simple-button
        :value="$t('add')"
        :disabled="!meta.valid || imageUrl.length === 0"
        @click="setImages"
      />
    </Form>
    <div class="wrap">
      <the-image-preview
        v-for="(url, index) in imageUrls"
        :key="index"
        :src="url"
        :class="{'__main': index === 0}"
      >
        <svg-icon
          class="image-main"
          icon-name="star"
          @click="setMainImage(index)"
        />
        <span
          class="image-remove"
          @click="removeFile(index)">
          <svg-icon class="close-icon" icon-name="close"></svg-icon>
        </span>
      </the-image-preview>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@shared/components/SvgIcon'
import TheImagePreview from '@/components/ui/TheImagePreview'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import { Form } from 'vee-validate'
import InputWithLabelValidation from './InputWithLabelValidation'

export default {
  name: 'TheImageUploadForm',
  props: {
    offerKey: {
      type: String,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InputWithLabelValidation,
    SimpleButton,
    TheImagePreview,
    SvgIcon,
    Form,
  },
  data () {
    return {
      imageUrls: [],
      imageUrl: '',
      hoverUpload: false,
    }
  },
  methods: {
    setImages () {
      const offerIndex = this.offerKey
      let imageUrls = [...this.imageUrls]
      const link = this.imageUrl
      if (link.length > 0) {
        imageUrls.push(link)
        this.imageUrls = imageUrls
        this.imageUrl = ''
      }
      this.$emit('setImages', imageUrls)
      if (offerIndex) {
        this.$store.commit('setImagesToProduct', { offerIndex, imageUrls })
      }
    },
    async loadImage (imageFormData) {
      const maxImageSize = 10 * 1024 * 1024

      if (imageFormData?.image?.size > maxImageSize) {
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: this.$t('imageSizeErrorTitle'),
          description: this.$t('imageSizeErrorDescription'),
        })

        return false
      }

      this.imageUrl = await this.$store.dispatch('loadImages', imageFormData)
      this.setImages()
    },
    setMainImage (index) {
      const mainImageUrl = this.imageUrls[index]
      const filtered = this.imageUrls.filter((image, imgIndex) => {
        return imgIndex !== index
      })
      this.imageUrls = [mainImageUrl, ...filtered]
    },
    showTypeError (file) {
      const fileType = file?.type ? file.type.split('/')[1] : this.$t('nothing')
      this.$store.commit('showHintNotification', {
        type: 'ERROR',
        title: this.$t('imageTypeErrorTitle'),
        description: `${this.$t('imageTypeErrorDescription')} ${this.$t('youLoad')}: ${fileType.toUpperCase()}`,
      })
    },
    handleFileDrop (e) {
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return
      ([...droppedFiles]).forEach((f, i) => {
        if (/\.(jpe?g|png|gif)$/i.test(droppedFiles[i].name)) {
          this.loadImage({ image: f })
        } else {
          this.showTypeError(droppedFiles[i])
        }
      })
      this.hoverUpload = false
    },
    handleFileInput (e) {
      let filesArr = e.target.files
      if (!filesArr) return
      ([...filesArr]).forEach((f, i) => {
        if (/\.(jpe?g|png|gif)$/i.test(filesArr[i].name)) {
          this.loadImage({ image: f })
        } else {
          this.showTypeError(filesArr[i])
        }
      })
    },
    removeFile (fileKey) {
      this.$refs.fileupload.value = null
      this.imageUrls.splice(fileKey, 1)
    },
    fileDragIn () {
      this.hoverUpload = true
    },
    fileDragOut () {
      this.hoverUpload = false
    },
  },

  mounted () {
    this.imageUrls = this.images
  },

  watch: {
    images: {
      handler () {
        this.imageUrls = this.images
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
$filled: #FFBE16;

.wrap {
  display: flex;
  flex-wrap: wrap;
}
.image-remove {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
.close-icon {
  width: 10px;
  height: 10px;
  position: relative;
  vertical-align: top;
  fill: rgba(60,60,60,.5);
  transition: 0.3s all;
  cursor: pointer;
  &:hover {
    fill: #000;
  }
}
.container {
  border: 2px dashed pink;
  min-height: 150px;
}
.file-wrapper {
  text-align: center;
  width: 300px;
  height: 5em;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  overflow: hidden;
  background: gray;
}

.upload__input {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  opacity: 0.0;
  filter: alpha(opacity=0);
  height: 100%;
  width: 100%;
}

.upload__text-wrap {
  display: flex;
}

.upload {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border: 1px dashed $disabled-color;
  margin: 0 auto 20px auto;
  background: $color-white;
  &.active {
    background: transparent;
  }
  &__placeholder {
    @include placeholder;
    text-align: center;
    line-height: 20px;
  }
  &__click {
    color: $color-primary;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dashed;
  }
  &__button {
    height: 48px;
    border-radius: 0;
  }
  &__field {
    display: flex;
    align-items: center;
    justify-content: left;

    .base-input {
      box-sizing: border-box;
      border: 1px solid $color-line;
      height: 48px;
      margin-right: 20px;
      max-width: 440px;
    }
  }
}

.upload-icon {
  width: 42px;
  height: 42px;
  fill: $color-disabled;
  margin-bottom: 12px;
}
.photos-icon {
  fill: #d8d8d8;
  width: 100px;
  height: 100px;
}

.upload__field-text {
  color: #999;
  font-size: 14px;
}
.preview {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  &:nth-child(6n + 6) {
    margin-right: 0;
  }
}
.img {
  max-width: 100%;
  max-height: 100%;
}
.wrapper {
  margin-bottom: 20px;
}
.image-main {
  position: absolute;
  left: 4px;
  top: 4px;
  cursor: pointer;
  z-index: 1;
  fill: transparent;

  &:hover {
    fill: $filled;
  }
}

.__main .image-main {
  fill: $filled;
}
</style>
