<template>
  <div class="attribute-item">
    <div class="attribute-item-label">
      {{attribute.name}}
    </div>
    <base-select
      :value="selected"
      :options="labeledValues"
      :filterable="false"
      @open="onOpen"
      @input="selectAttr"
      @search="searchAttr"
    >
      <template #footer>
        <li ref="load" class="loader" v-show="hasNextPage">
          {{ $t('loadAttrs') }}
        </li>
      </template>
    </base-select>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'AttributeItem',
  props: {
    attribute: {
      type: Object,
    },
    selected: {
      type: Object,
    },
  },
  data () {
    return {
      selectedValue: null,
      values: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 20,
      search: '',
      observer: null,
    }
  },

  async mounted () {
    this.observer = new IntersectionObserver(this.infiniteScroll)

    const getAttrValues = await api.getAttrValues({
      keyId: this.attribute.id,
      page: this.currentPage,
    })
    this.values = getAttrValues.data.items
    this.totalPages = getAttrValues.data.pagination.pages

    if (this.attribute.selected) {
      this.selectedValue = this.attribute.selected
    }
  },

  computed: {
    hasNextPage () {
      return this.totalPages > this.currentPage
    },
    labeledValues () {
      return this.values.map(value => {
        value.label = value.nameEn
        return value
      })
    },
    searchParams () {
      const params = {
        keyId: this.attribute.id,
        page: this.currentPage,
        perPage: this.perPage,
      }

      if (this.search) {
        params.name = this.search
      }

      return params
    },
  },

  methods: {
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        this.currentPage += 1
        const getAttrValues = await api.getAttrValues(this.searchParams)
        this.values.push(...getAttrValues.data.items)
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },

    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },

    onClose () {
      this.observer.disconnect()
      this.resetParams()
    },

    selectAttr (attrValue) {
      this.selectedValue = attrValue
      if (attrValue) {
        this.$emit('select', {
          id: attrValue.id,
          key: {
            id: this.attribute.id,
            name: this.attribute.name,
          },
          value: {
            id: attrValue.id,
            name: attrValue.label,
          },
        })
      } else {
        this.$emit('removeAttribute', this.attribute.id)
      }
    },

    async searchAttr (query) {
      this.search = query
      this.currentPage = 1

      const getAttrValues = await api.getAttrValues(this.searchParams)

      this.values = getAttrValues.data.items
      this.totalPages = getAttrValues.data.pagination.pages
    },

    resetParams () {
      this.search = ''
      this.currentPage = 1
      this.totalPages = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.attribute-item {
  margin-bottom: 20px;

  &-label {
    color: $text-gray;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
</style>
