<template>
  <div class="attribute-list">
    <AttributeItem
      v-for="attribute in withSelected"
      :key="attribute.id"
      :attribute="{ id: attribute.id, name: attribute.name }"
      :selected="attribute.selected"
      @select="$emit('select', $event)"
      @removeAttribute="$emit('removeAttribute', $event)"
    />
  </div>
</template>

<script>
import AttributeItem from '../list-items/AttributeItem'

export default {
  name: 'AttributeList',
  components: { AttributeItem },
  props: {
    attributes: {
      type: Array,
      default: () => ([])
    },
    selectedAttributes: {
      type: Array,
    },
  },
  computed: {
    withSelected () {
      return this.attributes.map(attribute => {
        const selected = this.selectedAttributes?.find(attr => {
          const keyId = attr.key.id ? attr.key.id : attr.key
          return attribute.id === keyId
        });

        if (selected) {
          attribute.selected = {
            id: selected.id || selected.key,
            label: selected.value.name || selected.value,
            nameEn: selected.value.name || selected.value,
          }
        } else {
          attribute.selected = null
        }

        return attribute
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.attribute-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  padding-bottom: 10px;
}
</style>
