<template>
  <div class="panel">
    <header
      class="panel-header"
      :class="{ '__inline': contentFitHeader }"
      @click="toggle"
    >
      <div class="panel-header__content">
        <slot name="header"></slot>
      </div>
      <div class="panel-header__icon" v-if="showIcon">
        <slot name="icon">
          <svg-icon
            class-name="panel-icon"
            :class="{ open: isOpen }"
            icon-name="expand-arrow"
          />
        </slot>
      </div>
    </header>
    <transition
      @before-enter="show"
      @enter="hide"
      @before-leave="hide"
      @leave="show"
    >
      <section class="panel-content" v-if="isOpen" ref="contentRef">
        <slot name="content"></slot>
      </section>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'BaseExpansionPanel',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    contentFitHeader: {
      type: Boolean,
      default: false,
    },
    calcOnUpdate: {
      type: Boolean,
      default: false,
    },
    noToggle: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      isOpen: this.open,
    }
  },
  methods: {
    toggle () {
      if (this.noToggle === false) {
        this.isOpen = !this.isOpen
        this.onToggle(this.isOpen)
      }
    },

    onToggle (state) {
      this.$emit('onToggle', state)
    },

    show (element) {
      element.style.height = '0'
      element.style.opacity = '0'
    },
    hide (element) {
      element.style.height = 'auto'
      element.style.opacity = '1'
    },
  },

  updated () {
    if (this.calcOnUpdate) {
      if (this.isOpen) {
        this.$el.style.height = `${this.$el.scrollHeight}px`
      } else {
        this.$el.style.height = 'auto'
      }
    }
  },

  watch: {
    open () {
      this.isOpen = this.open
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {

  &-header {
    padding: 0 37px 0 0;
    @include flex(row, space-between, center);
    position: relative;
    cursor: pointer;
  }

  &-header.__inline {
    display: inline-flex;
    padding-bottom: 20px;
  }

  &-content {
    transition: .3s ease all;
  }

  &-icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
    fill: transparent;
    transition: .3s ease all;

    &.open {
      transform: rotate(180deg);
    }
  }

  &-header.__inline &-icon {
    top: 0;
  }
}
</style>
